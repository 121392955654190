import React from 'react'
import styled from 'styled-components'

export const Logo = () => {
    return <LogoIcon>
        <svg width="41" height="55" viewBox="0 0 41 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.3386 20.3718V27.4297L32.9045 21.7241L25.7126 28.295L14.8814 18.9246L1.40495 30.9368V20.3718C1.40495 9.8967 9.8967 1.40495 20.3718 1.40495C30.8469 1.40495 39.3386 9.8967 39.3386 20.3718ZM39.3386 53.3881V29.3074L32.9186 23.6143L26.7824 29.2205L29.9636 31.9727L29.0444 33.0352L14.8953 20.7943L1.40495 32.8189V53.3881H39.3386ZM0 20.3718C0 9.12076 9.12076 0 20.3718 0C31.6228 0 40.7436 9.12076 40.7436 20.3718V54.7931H0V20.3718ZM19.0905 47.9646C14.2922 48.3458 12.1365 42.7794 10.5371 38.2805L10.6675 38.3234C12.7213 38.9987 15.0252 39.7562 16.7957 41.0256C16.5175 43.5419 17.0043 45.982 19.0905 47.9646ZM21.9415 47.8883C26.8788 48.5746 29.1041 42.8557 30.7035 38.2805C28.4782 38.9668 26.0444 39.8056 24.1668 41.2544C24.3754 43.6944 23.8886 45.982 21.9415 47.8883ZM20.4813 47.5833C24.9319 44.0757 22.5675 38.128 20.4813 33.4766C18.3951 38.128 16.0308 44.0757 20.4813 47.5833Z" fill="white" />
        </svg>

    </LogoIcon>
}

const LogoIcon = styled.div`
    width:41px;
    height:55px;
    right: 10%;
    top:60px;
    position:fixed;
    z-index: 5;
`